import React from 'react';
import { Spin } from 'antd';
import type { SpinSize } from 'antd/lib/spin';
import './styles.scoped.less';

export class LoadingProps {
  size?: SpinSize = 'default';
}

function Loading(props: LoadingProps) {
  return (
    <div className="loading-wrapper">
      <Spin className="loading" size={props.size} />
    </div>
  );
}

export default Loading;
